import React from "react";

import { Link, graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";

// import Custom Components
import Breadcrumb from "../components/common/breadcrumb";
import MainLayout from "../layouts/MainLayout";

// import { isIEBrowser } from "../utils";

function SingleFullWidth({  data, pageContext }) {
  
  const queryPost = data.post;
  const { breadCrumbLink } = pageContext;

  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  };

  return (
    <MainLayout>
      <div className="main single-2">
        <Breadcrumb
          title={`${queryPost?.title}`}
          adClass="breadcrumb-nav border-0 mb-0"
          parent={[breadCrumbLink]}
        />

        <div className="page-content">
          <figure className="entry-media">
            <div className="lazy-overlay bg-3"></div>
            {/* image will be added after configurations  */}
            {/* <GatsbyImage
              image={queryPost?.image}
              width={100}
              height={400}
            /> */}
          </figure>

          <div className="container">
            <article className="entry single-entry entry-fullwidth">
              <div className="row">
                <div className="col-lg-11">
                  <div className="entry-body">
                    <div className="entry-meta">
                      {queryPost?.title ? (
                        <span className="entry-author">
                          by <Link to="#">{queryPost.author||'anonymous'}</Link>
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="meta-separator">|</span>
                      <Link to="#">
                        {new Date(queryPost.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </Link>
                    </div>

                    <h2 className="entry-title entry-title-big">
                      {queryPost?.title}
                    </h2>

                    <div className="entry-content editor-content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div>${queryPost?.content}</div>`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <nav
              className="pager-nav"
              aria-label="Page navigation"
              // style={isIEBrowser() ? { flexFlow: "wrap" } : {}}
            >
              <Link className="pager-link pager-link-prev" to={`/blog`}>
                Go back to all blog posts
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default SingleFullWidth;
export const query = graphql`
  query MyPost($id: String) {
    post: pages(type: { eq: "blog" }, id: { eq: $id }) {
      createdAt
      title
      content
      author
      slug
      id
    }
  }
`;
